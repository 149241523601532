import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import '../styles/UserPage.css';

const UserPage = () => {
  const location = useLocation();
  const { raspberryToken } = location.state || {};
  const [videoUrl, setVideoUrl] = useState('');
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const [workingPlan, setWorkingPlan] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedPlan, setEditedPlan] = useState('');
  const [updateStatus, setUpdateStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLastVideo = async () => {
      if (!raspberryToken) {
        console.error('No Raspberry Token provided');
        return;
      }

      try {
        const baseURL = 'https://get-latest-video-o7utgv2yoq-uc.a.run.app';
        const firebaseAuthToken = localStorage.getItem('firebaseAuthToken');
        if (!firebaseAuthToken) {
          console.error('No Firebase Auth Token available');
          return;
        }
        const response = await axios.get(`${baseURL}/get-last-video`, {
          params: { raspberry_token: raspberryToken },
          headers: {
            'Authorization': `Bearer ${firebaseAuthToken}`,
            'Content-Type': 'application/json',
          }
        });

        setVideoUrl(response.data.videoUrl);
      } catch (error) {
        console.error('Error fetching the last video:', error);
      }
    };

    fetchLastVideo();
    fetchWorkingPlan();
  }, [raspberryToken]);

  const fetchWorkingPlan = async () => {
    setIsLoading(true);
    try {
      const db = getFirestore();
      const docRef = doc(db, 'raspberry_data', raspberryToken);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const planData = typeof data.working_plan === 'string' 
          ? JSON.parse(data.working_plan) 
          : data.working_plan || {};
        setWorkingPlan(planData);
        setEditedPlan(planData);
      }
    } catch (error) {
      console.error('Error fetching working plan:', error);
      setUpdateStatus('Error fetching working plan');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    const formattedPlan = formatJSON(workingPlan)
      .replace(/#[^\n]*(?=\n)/g, ',')
      .replace(/\n\s*\n/g, '\n')
      .trim();
    setEditedPlan(formattedPlan);
  };

  const handleSave = async () => {
    try {
      const db = getFirestore();
      const docRef = doc(db, 'raspberry_data', raspberryToken);
      
      // First clean up any trailing commas and whitespace
      let cleanJson = editedPlan.toString()
        .replace(/#[^\n]*/g, '')  // Remove comments
        .replace(/\s*,\s*}/g, '}')  // Remove trailing commas
        .replace(/\s*,\s*\n\s*]/g, ']')  // Remove trailing commas in arrays
        .trim();

      console.log('Cleaned JSON:', cleanJson); // For debugging
      
      const parsed = JSON.parse(cleanJson);
      console.log('Parsed JSON:', parsed); // For debugging
      
      await updateDoc(docRef, {
        working_plan: parsed
      });

      setWorkingPlan(parsed);
      setIsEditing(false);
      setUpdateStatus('Working plan updated successfully!');
      
      setTimeout(() => {
        setUpdateStatus('');
      }, 3000);
      
    } catch (error) {
      console.error("Error updating working plan:", error);
      console.error("JSON that failed:", editedPlan); // For debugging
      setUpdateStatus(`Error: Invalid JSON format. ${error.message}`);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedPlan(workingPlan); // Reset to original value
  };

  const handleSpeedChange = (e) => {
    setPlaybackSpeed(parseFloat(e.target.value));
  };

  const formatJSON = (obj) => {
    const keyOrder = [
      'Day',
      'Day_Flash',
      'Night',
      'Night_Flash',
      'conditionalScript',
      'dailySchedule'
    ];

    const jsonObj = typeof obj === 'string' ? JSON.parse(obj) : obj;
    
    const customStringify = (obj, indent = '') => {
      if (!obj || typeof obj !== 'object') {
        return JSON.stringify(obj);
      }

      if (Object.keys(obj).length === 0) {
        return '{}';
      }

      // Keep objects with only string values on one line
      const allValuesAreStrings = Object.values(obj).every(v => typeof v === 'string');
      if (allValuesAreStrings) {
        return `{${Object.entries(obj)
          .map(([k, v]) => `"${k}": "${v}"`)
          .join(', ')}}`;
      }

      const entries = Object.entries(obj);
      const sortedEntries = entries.sort(([keyA], [keyB]) => {
        const indexA = keyOrder.indexOf(keyA);
        const indexB = keyOrder.indexOf(keyB);
        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });

      let result = '{\n';

      sortedEntries.forEach(([key, value], index) => {
        const isLast = index === sortedEntries.length - 1;
        let comment = '';
        
        switch(key) {
          case 'Day':
          case 'Night':
          case 'Day_Flash':
          case 'Night_Flash':
            comment = ' #can only change values';
            break;
          case 'conditionalScript':
            break;
          case 'dailySchedule':
            break;
          default:
            break;
        }

        if (key === 'dailySchedule') {
          result += `${indent}  "${key}": ${comment}\n${indent}  {\n`;
          const sortedTimes = Object.entries(value)
            .sort(([timeA], [timeB]) => {
              const [hoursA, minutesA] = timeA.split(':').map(Number);
              const [hoursB, minutesB] = timeB.split(':').map(Number);
              return (hoursA * 60 + minutesA) - (hoursB * 60 + minutesB);
            });
          
          sortedTimes.forEach(([time, settings], timeIndex) => {
            const timeIsLast = timeIndex === sortedTimes.length - 1;
            result += `${indent}    "${time}": ${customStringify(settings)}${timeIsLast ? '' : ','}\n`;
          });
          result += `${indent}  }`;
        } else {
          const valueStr = typeof value === 'object' && value !== null
            ? customStringify(value, indent + '  ')
            : JSON.stringify(value);
          result += `${indent}  "${key}": ${valueStr}${comment}`;
        }
        
        result += isLast ? '\n' : ',\n';
      });

      return result + indent + '}';
    };

    return customStringify(jsonObj);
  };

  return (
    <div className="user-page-container">
      <h1>Your Video</h1>
      {videoUrl ? (
        <div className="video-container">
          <video
            controls
            src={videoUrl}
            ref={(videoElement) => {
              if (videoElement) {
                videoElement.playbackRate = playbackSpeed;
              }
            }}
            style={{ width: '100%' }}
          >
            Your browser does not support the video tag.
          </video>
          <div className="controls">
            <label htmlFor="speed">Playback Speed:</label>
            <input
              type="range"
              id="speed"
              min="0.5"
              max="10.0"
              step="0.1"
              value={playbackSpeed}
              onChange={handleSpeedChange}
            />
            <span>{playbackSpeed}x</span>
          </div>
        </div>
      ) : (
        <p>Loading video...</p>
      )}

      <div className="working-plan-section">
        <h2>Working Plan</h2>
        {updateStatus && (
          <div className={`status-message ${updateStatus.includes('Error') ? 'error' : 'success'}`}>
            {updateStatus}
          </div>
        )}
        
        {isLoading ? (
          <div className="loading">Loading working plan...</div>
        ) : (
          isEditing ? (
            <div className="edit-plan-container">
              <textarea
                value={typeof editedPlan === 'string' ? editedPlan : formatJSON(editedPlan)}
                onChange={(e) => {
                  setEditedPlan(e.target.value);
                  setUpdateStatus('');
                }}
                rows="25"
                className="plan-editor"
                spellCheck="false"
              />
              <div className="edit-buttons">
                <button 
                  onClick={handleSave} 
                  className="save-button"
                  disabled={updateStatus.includes('Invalid')}
                >
                  Save
                </button>
                <button onClick={handleCancel} className="cancel-button">Cancel</button>
              </div>
            </div>
          ) : (
            <div className="plan-display">
              <pre>{formatJSON(workingPlan)}</pre>
              <button onClick={handleEditClick} className="edit-button">Edit Plan</button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UserPage;
